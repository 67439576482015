// Copyright 2021-2022 Northwestern University

// ********************************
// Custom label overrides/variations
// ********************************

.ui.label {
  color: @prismGreyed;
}

.ui.label.access-status.embargoed {
  background-color: #956E04;
}
