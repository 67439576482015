// Copyright 2021-2024 Northwestern University

// Custom button variations

.record-management {
  .ui.warning.button, .ui.positive.button, .ui.primary.button{
    background-color: @nmPurple;

    &:hover {
      background-color: saturate(darken(@nmPurple, 5), 10, relative);
    }
  }

  .ui.fluid.labeled.icon.button {
    // Back to what it was before overridden by core Invenio module
    padding-left: 1.5em !important;
  }
}
