// Copyright 2021-2022 Northwestern University

// form overrides

#deposit-form {

    .ui.inverted.brand.segment {
        background-color: @brandColor;  //!important;
    }

}
