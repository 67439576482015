// Copyright 2021-2022 Northwestern University

// Custom container variations

.ui.text.container.statement-of-purpose {
  text-align: center;
  margin-bottom: .75em;
  font-size: larger;
  max-width: 1000px !important;
  color: #333;
}
