// Copyright 2021-2023 Northwestern University

// **********************
// Overall site overrides
//
// Overrides https://github.com/Semantic-Org/Semantic-UI/blob/master/src/definitions/globals/site.less
// Anything that isn't cleanly a Semantic-UI element is also styled here.
// **********************

// Common utility css
// ******************

// Miscellaneous utilities
.titlecase {
  text-transform: capitalize;
}

.wrap {
  // NOTE: Only apply this to an element with a (max-)width already set;
  overflow-wrap: break-word;
}

.webkit-box {
  overflow: hidden; // overflow hidden needed to 'clip' content first
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
}

.hide {
  visibility: hidden;
}

.prism-raised {
  box-shadow: 0 -2px 20px 0 rgba(34, 36, 38, .13) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.object-contain {
  object-fit: contain;
}

.h-300px {
  height: 300px !important;
}

.w-auto {
  width: auto !important;
}

.hover-underline:hover {
  text-decoration: underline;
}

.z-index-2 {
  position: relative;
  z-index: 2;
}

// Color utilities
.white {
  color: white !important;
}

.nmPurpleTint70 {
  color: @nmPurpleTint70;
}

.hover-nmPurpleTint70Hover:hover {
  color: @nmPurpleTint70Hover;
}

// Margin utilities

.mb-2rem {
  margin-bottom: 2rem !important;
}

.mt-2rem {
  margin-top: 2rem !important;
}

.mt-14rem {
  margin-top: 14rem !important;
}

.mb-1-5rem {
  margin-bottom: 1.5rem !important;
}

.mb-5rem {
  margin-bottom: 5rem !important;
}

.mb-neg-8rem {
  margin-bottom: -8rem !important;
}

// Already existing ones:
// m-0 : margin: 0 !important;
// mt-0 : margin-top: 0 !important;

// Font-size utilities

.fs-12px {
  font-size: 12px !important;
}

// 16px is default

.fs-18px {
  font-size: 18px !important;
}

.fs-20px {
  font-size: 20px !important;
}

// large
.fs-24px {
  font-size: 24px !important;
}

// Background utilities
.bgc-nmPurple {
  background-color: @nmPurple !important;
}

.bgc-nmPurpleTint70 {
  background-color: @nmPurpleTint70 !important;
}

.bgc-nmPurpleTint30 {
  background-color: @nmPurpleTint30 !important;
}

.bgc-nmGreyTint25 {
  background-color: @nmGreyTint25 !important;
}

// Padding utilities
.pl-1em {
  padding-left: 1em !important;
}

.pa-2rem {
  padding: 2rem !important;
}

.pa-0-5rem {
  padding: 0.5rem !important;
}

.plr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pt-0-5rem {
  padding-top: 0.5rem;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

// Border utilities
.border-t-solid {
  border-top-style: solid;
}

.border-t-nmPurpleTint70 {
  border-top-color: @nmPurpleTint70;
}

.border-t-nmGreyTint50 {
  border-top-color: @nmGreyTint50;
}

.border-t-0-3rem {
  border-top-width: 0.3rem;
}

.border-t-0-4rem {
  border-top-width: 0.4rem;
}

.border-br {
  border-bottom-right-radius: @borderRadius !important;
  border-bottom-left-radius: @borderRadius !important;
}

.border-brr-0 {
  border-bottom-right-radius: 0 !important;
}

.border-blr-0 {
  border-bottom-left-radius: 0 !important;
}

// Link utilities
.link-searchHeaderColor {
  color: @searchHeaderLinkColor;

  &:hover {
    color: @searchHeaderLinkHoverColor;
    text-decoration: underline;
  }
}

// Any page
// ********
html {
  font-size: @fontSize;
}

.prism-textful {
  p, ul li {
    font-size: @fontSize + 2px;
  }

  h2 {
    font-size: @h3;
  }
}

a {
  text-decoration: none;
  color: @primaryColor;

  &:hover {
    color: @linkHoverColor;
  }
}

::placeholder {
  color: @nmGrey !important;
}

// Sign up page
// ************
.prism-signup {
  h5.ui.header {
    margin-top: 0;
  }

  a {
    font-weight: 800;
  }

  .ui.checkbox label {
    font-size: 1.2em;
  }
}


// Frontpage
// *********
#frontpage-search-section {
  // background-color is used as default (backup if background-image fails)
  // it also loads first (progressive loading pattern) and satisfies
  // accessibility needs
  background-color: @nmPurple;
  background-image: url('../../../images/prism_poly.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 800px;
  margin-top: 0;

  h1.tagline {
    color: white;
    text-shadow: 0px 0px 3px #000;
    font-size: 3rem;
    margin: 0;
  }

  .search.button {
    background-color: @primaryColor;
  }

  a {
    color: white;
  }
}

.home-divider {
  &:before {
    background: url('../../../images/grey-slash.svg') no-repeat;
    padding: 0 20px;
    content: " "
  }

  &:after {
    background: url('../../../images/grey-slash.svg') no-repeat 10px;
    padding: 0 20px;
    content: " ";
  }
}

.focus-on-links > a {

  color: white;

  &:hover,
  &:focus {
    opacity: .7;
  }

  text-decoration: none;
}

.mb-hero-overlap {
  margin-bottom: -19.3rem !important;
}

.column.overlapping-down {
  bottom: -6rem;
  z-index: 2;
}

.column.overlapping-up {
  top: -10.5rem;
  z-index: 2;
}

#frontpageRecords .creatibutors .creatibutor-wrap {
  margin-bottom: 0;
}

/* Mobile Sizing Combinations */
@media only screen and (min-width: @mobileBreakpoint) and (max-width: @largestMobileScreen) {

  .column.overlapping-down {
    bottom: unset;
    z-index: unset;
  }

  .column.overlapping-up {
    top: unset;
    z-index: unset;
  }

  .mobile-mb-1rem {
    margin-bottom: 1rem !important;
  }

  .mobile-mb-2rem {
    margin-bottom: 2rem !important;
  }

}

/* Tablet Sizing Combinations */
@media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {

  .column.overlapping-down {
    bottom: unset;
    z-index: unset;
  }

  .column.overlapping-up {
    top: unset;
    z-index: unset;
  }

  .mobile-mb-1rem {
    margin-bottom: 1rem !important;
  }

  .mobile-mb-2rem {
    margin-bottom: 2rem !important;
  }

}

/* Small monitor Sizing Combinations */

@media only screen and (min-width: @computerBreakpoint) and (max-width: @largestSmallMonitor) {

  .mb-hero-overlap {
    margin-bottom: -19.85rem !important;
  }

}


// Header
// *********
.theme.header {
  box-shadow: none;

  .outer-navbar {

    a {
      color: white;

      &.ui.button {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    #invenio-nav.ui.menu>.container {
      margin-top: 0;
      margin-bottom: 0;
    }

  }

  .title-link {
    font-size: 40px;
  }

  a {
    font-weight: normal;

    &.close {
      color: black;
    }

    &:hover {
      opacity: .7;
      text-decoration: none;
    }

  }

  #organization-header {
    background: transparent;
    padding-top: 1em;

    img.institutionLogo {
      width: 240px;
      height: 40px;
    }

    .organization-links {
      padding: 0;
    }

  }

  div[id$='-message-banner'] {
    margin: 0;

    a {
      font-weight: bold;
    };

    .invenio-banner-container {
      display: flex;
      justify-content: space-between;
    }
  }

}


// Footer
// *********
#footer,
.footer-global {
  color: white;
  letter-spacing: 0.4px;
  line-height: 1.8em;
  word-wrap: break-word;
  margin-top: auto;
  padding-top: 1rem;

  &>.ui.grid {
    margin: 0rem; // Default was `-1rem -1rem`
  }

  .container .row {
    padding-top: 2.16667%;
  }

  p {
    margin: 1em 0;
  }

  a {
    color: white;
    text-decoration: underline;

    &:hover,
    &:focus {
      opacity: .7;
    }
  }

  img#nu-logo {
    width: 170px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 10px 0px;
    }
  }

  .social-icons {
    li {
      float: left;
    }

    .icon {
      text-decoration: none;
    }

    .facebook.icon:hover {
      background-color: #004088;
    }

    .twitter.icon:hover {
      background-color: #00abe3;
    }

    .instagram.icon:hover {
      background-color: #517FA6;
    }
  }

  .logo img {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px 5px 5px 0px;

    &#coar-logo {
      width: 60%;
    }
  }
}

// Record Landing Page
// *********
#upload-info dl div.table-row dt, #upload-info dl div.table-row dd {
    color: #757575;
}

#recordManagement > div.record-management > div.column:nth-child(1) {
  // Back to what it was before overridden by core Invenio module
  padding-top: 1rem !important;
}

// Pragmatic styling
//
// This is the kind of CSS that is applied to an already used class,
// because adding a utility class in the template would require duplicating
// the original just for a small change.
.rdm-tab-container {
  overflow-wrap: break-word;
}
