// Copyright 2022-2023 Northwestern University

// ********************************
// Custom card overrides/variations
// ********************************

.ui.spaced-around.cards {
  justify-content: space-around;
}


// Collect Share Preserve variation
// ********************************
.prism-collect-share-preserve .ui.cards .card {
  background: url('../../../images/grey-facets-small.png') no-repeat center / cover;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  line-height: @prismSectionLineHeight;

  .content {
    font-size: larger;
    border-top: none;

    a {
      text-decoration: underline dotted;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}


// Prism recent uploads cards
// **************************
.prism-recent-uploads {

  .ui.cards::after {
    content: none;
  }

  .ui.cards > .card.ui.card.prism-record-card {
    width: calc(27% - 2em); // value chosen because it looked good
    aspect-ratio: 2 / 3;
    text-align: left;

    & > .content  {
      border-top: none !important;
      padding: 1rem 2rem;

      & > .description {
        max-height: 8 * @prismSectionLineHeight;
        overflow-y: hidden;
        color: @nmGrey;

        &:after {
          content: "";
          position: absolute;
          bottom: 2.5 * @prismSectionLineHeight;
          left: 0;
          width: 100%;
          height: @prismSectionLineHeight;
          background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        }
      }
    }

    & > .extra.content {
      padding-top: 1rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;

      &:last-child {
        color: @nmGrey;
        font-style: italic;
      }
    }
  }

}

/* Mobile Sizing Combinations */
@media only screen and (min-width: @mobileBreakpoint) and (max-width: @largestMobileScreen) {

  .prism-recent-uploads {

    .ui.cards {
      flex-direction: column;
      align-items: center;
    }

    .ui.cards > .card.ui.card.prism-record-card {
      width: calc(90% - 2em); // value chosen because it looked good

      & > .content > .description {
        max-height: 6 * @prismSectionLineHeight;
      }
    }

  }
}

/* Tablet Sizing Combinations */
@media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {

  .prism-recent-uploads {

    .ui.cards {
      flex-direction: column;
      align-items: center;
    }

    .ui.cards > .card.ui.card.prism-record-card {
      width: calc(60% - 2em); // value chosen because it looked good
    }

  }
}

/* Computer Sizing Combinations */
@media only screen and (min-width: @computerBreakpoint) and (max-width: @largestSmallMonitor) {

  .prism-recent-uploads {

    .ui.cards > .card.ui.card.prism-record-card {

      & > .content > .description {
        max-height: 8 / 1.5 * @prismSectionLineHeight;
      }
    }

  }
}


.ui.raised.card.prism-record-card {
  box-shadow: 0 -2px 20px 0 rgba(34, 36, 38, .13);
}


@media only screen and (max-width : @largestMobileScreen) {
  .ui.stackable.cards .card:first-child {
    margin-top: 1em !important;
  }
}


// Until inveniordm-module contribution is merged back in
.cards.community-frontpage-cards .card .content .header {
  overflow-wrap: break-word;
}
