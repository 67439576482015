// Copyright 2022 Northwestern University


/* Header - Link */
.ui.items > .item > .content > .header > a {
  color: @searchHeaderLinkColor;

  &:hover {
    color: @searchHeaderLinkHoverColor;
  }
}
