// Copyright 2021-2023 Northwestern University

// Custom header variations

.prism-underlined-header.header {
  text-decoration-line: underline;
  text-decoration-color: @nmPurpleTint30;
  text-decoration-thickness: 0.4rem;
  text-underline-position: under;
}

.prism-skinny-header {
  font-weight: normal !important;
  font-size: 2rem;
}
